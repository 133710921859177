import {useContext} from 'react';
import {AlertContext} from '../context/AlertContext';
import Button from './Button';
import Icon, {IconType} from './Icon';

import '../scss/components/_alert.scss';

export default function Alert() {
    const {alert, cleanAlert} = useContext(AlertContext);

    const icon = {
        error: 'faFire',
        success: 'faRocket',
        warning: 'faHeartbeat',
        info: 'faBolt',
    };

    return alert ? (
        <div className={`alert__container alert-color-${alert.type}`}>
            <Icon icon={icon[alert.type] as IconType} />
            <div className="mx-3 w-100">
                <strong className="body-l-sb color-text-01">
                    {alert.title}
                </strong>
                <p className="body-l">{alert.text}</p>
            </div>
            <Button
                onClick={cleanAlert}
                className="button__only-icon-withoutcolor icons__36"
                icon="faClose"
                variation="terciary"
            />
        </div>
    ) : (
        <></>
    );
}
