export const getLabel = (name: string) => {
    switch (name) {
        case 'name':
            return 'Nombre';
        case 'origin':
            return 'Origen';
        case 'flavor':
            return 'Sabor';
        case 'description':
            return 'Descripción';
        case 'type':
            return 'Tipo';
        case 'extra_tip':
            return 'Tip extra';
        case 'ingredients':
            return 'Ingredientes';
        case 'tools':
            return 'Utensillos';
        case 'steps':
            return 'Paso a paso';
        case 'category':
            return 'Categoría';

        default:
            return name[0].toUpperCase() + name.slice(1);
    }
};
