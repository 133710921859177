import Input from './inputs/Input';
import Button from '../Button';

import {useForm} from '../../hooks/useForm';
import {Ingredient, Recipes, Tool} from '../../utils/types';
import {getLabel} from '../../utils/getLabels';
import {useEffect} from 'react';
import {ActionMeta, MultiValue, SingleValue} from 'react-select';

interface FormProps {
    INITIAL_STATE: Tool | Ingredient | Recipes;
    onSubmit: (arg: Tool | Ingredient | Recipes) => void;
    categoryOptions: {value: string; label: string}[];
    disabled: boolean;
}

function Form({INITIAL_STATE, onSubmit, categoryOptions, disabled}: FormProps) {
    const {values, handleInputChange, setValues, setArraysValues, resetForm} =
        useForm(INITIAL_STATE);

    useEffect(() => {
        resetForm();
    }, [INITIAL_STATE]);

    const submit = async (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(values);
    };

    const createType = (key: keyof (Tool | Ingredient | Recipes), val: any) => {
        if (key === 'img') {
            return 'file';
        }
        switch (typeof val) {
            case 'number':
                return 'number';
            case 'bigint':
                return 'number';
            case 'object':
                return 'array';
            default:
                return 'text';
        }
    };

    return (
        <form onSubmit={submit} className="px-2">
            {Object.entries(INITIAL_STATE).map(([key, val]) => (
                <Input
                    key={key}
                    inputState="normal"
                    name={key}
                    onChange={handleInputChange}
                    setArraysValues={setArraysValues}
                    value={values[key as keyof (Tool | Ingredient | Recipes)]}
                    label={getLabel(key)}
                    setArrayValue={(
                        newValue:
                            | SingleValue<{
                                  value: string;
                                  label: string;
                              }>
                            | MultiValue<{
                                  value: string;
                                  label: string;
                              }>,
                        actionMeta: ActionMeta<{
                            value: string;
                            label: string;
                        }>,
                    ) => {
                        if (!Array.isArray(newValue)) {
                            const val: {
                                value: string;
                                label: string;
                            } = newValue as {
                                value: string;
                                label: string;
                            };
                            setValues({
                                ...values,
                                [actionMeta.name as string]: val?.value || '',
                            });
                        } else {
                            setValues({
                                ...values,
                                [actionMeta.name as string]: newValue.map(
                                    op => op.value,
                                ),
                            });
                        }
                    }}
                    className="mb-3"
                    type={createType(
                        key as keyof (Tool | Ingredient | Recipes),
                        val,
                    )}
                    selectOptions={categoryOptions}
                />
            ))}
            <Button
                className="w-100 mt-5"
                text="Guardar"
                disabled={disabled}
                type="submit"
            />
        </form>
    );
}

export default Form;
