import {useParams} from 'react-router';
import IngredientsForm from './IngredientsForm';
import RecipesForm from './RecipesForm';

import ToolsForm from './ToolsForm';

function FormSwitched({lang}: {lang: string}) {
    const {section} = useParams();

    switch (section) {
        case 'ingredients':
            return <IngredientsForm lang={lang} />;

        case 'tools':
            return <ToolsForm lang={lang} />;

        default:
            return <RecipesForm lang={lang} />;
    }
}

export default FormSwitched;
