import {Dispatch, SetStateAction} from 'react';
import {Col} from 'react-bootstrap';

import Button from './Button';

interface LangSelectorProps {
    setLang: Dispatch<SetStateAction<string>>;
}

function LangSelector({setLang}: LangSelectorProps) {
    return (
        <Col md={6} xs={12} className="d-flex row__mobile">
            <Button
                text="Español"
                type="button"
                variation="terciary"
                className="start pl-0 m-2"
                onClick={() => setLang('es')}
            />
            <Button
                text="Inglés"
                type="button"
                variation="terciary"
                className="start pl-0 m-2"
                onClick={() => setLang('en')}
            />
            <Button
                text="Francés"
                type="button"
                variation="terciary"
                className="start pl-0 m-2"
                onClick={() => setLang('fr')}
            />
            <Button
                text="Portugués"
                type="button"
                variation="terciary"
                className="start pl-0 m-2"
                onClick={() => setLang('pt')}
            />
            <hr />
        </Col>
    );
}

export default LangSelector;
