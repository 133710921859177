import classNames from 'classnames';
import {ChangeEvent, useEffect, useState} from 'react';
import Button from '../../Button';

const INITIAL_VALUE = {value: '', label: ''};

interface SteperinputsProps {
    name: string;
    values: string | string[];
    onChange: (values: {value: string; label: string}[]) => void;
}

function StepersInputs({name, values, onChange}: SteperinputsProps) {
    const [isFocused, setIsFocused] = useState<number | null>(null);
    const [steps, setSteps] = useState([INITIAL_VALUE]);

    const addStep = () => {
        setSteps([...steps, INITIAL_VALUE]);
    };

    const deleteStep = (stepIndex: number) => {
        setSteps(steps.filter((s, i) => i !== stepIndex));
    };

    const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setSteps(
            steps.map((step, i) => {
                if (e.target.id === i.toString()) {
                    return {value: e.target.value, label: e.target.value};
                } else {
                    return step;
                }
            }),
        );
    };

    useEffect(() => {
        onChange(steps);
    }, [steps]);

    useEffect(() => {
        if (values.length && typeof values !== 'string') {
            setSteps(values.map(value => ({value: value, label: value})));
        } else {
            setSteps([INITIAL_VALUE]);
        }
    }, [values[0]]);

    return (
        <>
            {steps.map((step, i) => (
                <div className="d-flex my-3 ml-4" key={i}>
                    <span className="my-2 mr-3">{i + 1}.</span>
                    <div
                        className={classNames(
                            'input-container d-flex gap-8 border-ui-04 w-100 align-items-center',
                            {focused: i === isFocused},
                        )}>
                        <input
                            className="input-element w-100 border-0 p-0 body-m-normal bg-transparent"
                            name={name}
                            id={i.toString()}
                            onChange={handleInputChange}
                            value={step.label}
                            type="text"
                            onFocus={() => setIsFocused(i)}
                            onBlur={() => setIsFocused(null)}
                        />
                    </div>
                    <div className={classNames('d-flex align-items-center')}>
                        <Button
                            icon="faMinus"
                            type="button"
                            variation="terciary"
                            className="start p-0"
                            onClick={() => deleteStep(i)}
                        />
                    </div>
                    <div
                        className={classNames('d-flex align-items-center', {
                            'v-hidden': steps.length - 1 !== i,
                        })}>
                        <Button
                            icon="faPlus"
                            type="button"
                            variation="terciary"
                            className="start p-0"
                            onClick={addStep}
                        />
                    </div>
                </div>
            ))}
            <div
                className={classNames('d-flex align-items-center', {
                    'v-hidden': steps.length,
                })}>
                <Button
                    icon="faPlus"
                    type="button"
                    variation="terciary"
                    className="start p-0"
                    onClick={addStep}
                />
            </div>
        </>
    );
}

export default StepersInputs;
