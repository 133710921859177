import {useContext} from 'react';
import Select from 'react-select';

import {DataContext} from '../../../context/DataContext';

function ToolsInput(props: any) {
    const {tools} = useContext(DataContext);
    const options =
        tools?.map(tool => ({value: tool._id, label: tool.name})) || [];

    return (
        <Select
            className="select__container mb-3"
            classNamePrefix="select"
            defaultValue={options.filter(op => props.tools.includes(op.value))}
            isDisabled={false}
            isLoading={false}
            isClearable
            isSearchable
            name="name"
            options={options}
            isMulti
            {...props}
        />
    );
}

export default ToolsInput;
