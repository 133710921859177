import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';

import {Data, DataContext, DataState} from '../../context/DataContext';
import {AlertContext} from '../../context/AlertContext';
import Form from './Form';

import {getById, postCreateData, putEditData} from '../../utils/api';
import {Ingredient, Recipes, Tool} from '../../utils/types';

const INITIAL_STATE: Tool = {
    name: '',
    description: '',
    material: '',
    origin: '',
    category: '',
    img: '',
};

const CATEGORY_OPTIONS = [
    {
        value: 'coocktail',
        label: 'Coctelería',
    },
    {
        value: 'macheine',
        label: 'Maquinaria',
    },
    {
        value: 'glassware',
        label: 'Cristalería',
    },
    {
        value: 'crockery',
        label: 'Vajilla',
    },
    {
        value: 'cleaning',
        label: 'Limpieza',
    },
];

function ToolsForm({lang}: {lang: string}) {
    const [initialRecipe, setInitialRecipe] = useState(INITIAL_STATE);
    const [loading, setLoading] = useState(true);
    const {section, id} = useParams();
    const {updateData} = useContext(DataContext);
    const {createAlert} = useContext(AlertContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getById(section as keyof DataState, id, lang)
                .then(data => {
                    const {_id, createdAt, updatedAt, ...tool} = data.item;
                    setInitialRecipe({...INITIAL_STATE, ...tool});
                })
                .catch(err =>
                    createAlert({
                        type: 'error',
                        title: 'No lo logramos, por ahora..',
                        text:
                            err.data?.msj ||
                            err.data?.errors ||
                            JSON.stringify(err),
                    }),
                )
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [lang]);

    const onSubmit = async (values: Tool | Ingredient | Recipes) => {
        try {
            if (id) {
                const resp = await putEditData(
                    values,
                    section as keyof DataState,
                    lang,
                    id,
                );
                const {name} = resp.item as Data;
                createAlert({
                    type: 'success',
                    title: name,
                    text: resp.msj,
                });
            } else {
                const resp = await postCreateData(values, 'tools', lang);
                const {_id, name} = resp.item as Data;
                updateData({tools: [{_id, name}]}, 'updateTools');
                navigate(`/dashboard/data/edit/tools/${_id}`);
                createAlert({
                    type: 'success',
                    title: name,
                    text: resp.msj,
                });
            }
        } catch (error: any) {
            createAlert({
                type: 'error',
                title: 'No lo logramos, por ahora..',
                text: error.data.msj || error.data.errors,
            });
        }
    };

    return loading ? (
        <p>Loading</p>
    ) : (
        <Form
            INITIAL_STATE={initialRecipe}
            onSubmit={onSubmit}
            disabled={false}
            categoryOptions={CATEGORY_OPTIONS}
        />
    );
}

export default ToolsForm;
