import {Col, Container, Row} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import {User} from '../utils/types';
import Icon from './Icon';

import '../scss/components/_header.scss';
import '../scss/base/_settings.scss';
import Button from './Button';

interface HeaderProps {
    user: User | null;
    isDesktop: boolean;
    logout: () => void;
    handleMenuMobile: () => void;
}

export default function Header({
    user,
    isDesktop,
    logout,
    handleMenuMobile,
}: HeaderProps): JSX.Element {
    const navigate = useNavigate();

    const getUserType = (type: string[]) => {
        if (type.includes('admin')) {
            return 'Administrador';
        }
        if (type.includes('tech')) {
            return 'Técnico';
        }
        return 'Usuario';
    };

    return (
        <header className="header-container w-100">
            <Container>
                <Row className="header-row">
                    {user && !isDesktop ? (
                        <Col xs={2} className="header-col-title">
                            <Button
                                icon="faBars"
                                type="button"
                                variation="terciary"
                                className="start pl-0"
                                onClick={handleMenuMobile}
                            />
                        </Col>
                    ) : undefined}
                    <Col xs={10} md={4} className="header-col-title">
                        <Link
                            to="/"
                            className="d-flex flex-direction-row flex-no-wrap align-items-center text-decoration-none gap-8"
                            aria-label="Inicio">
                            <h1 className="text-nowrap m-0">
                                Seed Club - Backoffice
                            </h1>
                        </Link>
                    </Col>
                    {user && isDesktop && (
                        <Col
                            xs={{span: 'auto'}}
                            className="d-flex align-items-center gap-24">
                            <p className="m-0 body-l-bold color-interactive-02">
                                {getUserType(user.userType)}
                            </p>
                            <div className="vertical-line" />
                            <button
                                onClick={() => {
                                    logout();
                                    navigate('/');
                                }}
                                className="d-flex align-items-center gap-8 color-interactive-04 bg-transparent border-0 logout-button">
                                <Icon icon="faArrowRightFromBracket" />
                                <span className="body-s-bold">
                                    Cerrar sesión
                                </span>
                            </button>
                        </Col>
                    )}
                </Row>
            </Container>
        </header>
    );
}
