import {useState} from 'react';
import Select, {ActionMeta, MultiValue, SingleValue} from 'react-select';

import classNames from 'classnames';
import Button from '../../Button';
import ToolsInput from './ToolsInput';
import StepersInputs from './StepersInputs';
import IngredientsInputs from './IngredientsInputs';

import '../../../scss/components/_input.scss';

export type InputStateType =
    | 'success'
    | 'warning'
    | 'error'
    | 'normal'
    | 'disabled'
    | 'focus';

interface InputProps {
    inputState: InputStateType;
    name: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    setArraysValues?: (
        name: string,
        valuesSelected: {value: string; label: string}[],
    ) => void;
    setArrayValue?: (
        newValue:
            | SingleValue<{value: string; label: string}>
            | MultiValue<{value: string; label: string}>,
        actionMeta: ActionMeta<{value: string; label: string}>,
    ) => void;
    value: string | string[];
    helperMessage?: string;
    label?: string;
    placeholder?: string;
    type?: 'password' | 'text' | 'array' | 'number' | 'file';
    selectOptions?: {value: string; label: string}[];
    className?: string;
}

export default function Input({
    inputState,
    className = '',
    name,
    onChange,
    setArraysValues,
    setArrayValue = () => {},
    value,
    helperMessage,
    label,
    placeholder,
    selectOptions,
    type,
}: InputProps) {
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handlePicture = () => {
        const element: HTMLElement = document?.querySelector(
            `#${name}`,
        ) as HTMLElement;
        element.click();
    };

    if (name === 'tools') {
        return (
            <div className="input mb-3 gap-8">
                <label htmlFor={name} className="body-m-bold">
                    {label}
                </label>
                <ToolsInput
                    name={name}
                    tools={value}
                    onChange={(values: {value: string; label: string}[]) => {
                        if (setArraysValues) {
                            setArraysValues(name, values);
                        }
                    }}
                />
            </div>
        );
    }

    if (name === 'steps') {
        return (
            <div className="input mb-3 gap-8">
                <label htmlFor={name} className="body-m-bold">
                    {label}
                </label>
                <StepersInputs
                    name={name}
                    values={value}
                    onChange={(values: {value: string; label: string}[]) => {
                        if (setArraysValues) {
                            setArraysValues(name, values);
                        }
                    }}
                />
            </div>
        );
    }

    if (name === 'ingredients') {
        return (
            <div className="input mb-3 gap-8">
                <label htmlFor={name} className="body-m-bold">
                    {label}
                </label>
                <IngredientsInputs
                    values={JSON.stringify(value)}
                    onChange={(values: {value: string; label: string}[]) => {
                        if (setArraysValues) {
                            setArraysValues(name, values);
                        }
                    }}
                />
            </div>
        );
    }

    if (type === 'array' || name === 'category') {
        return (
            <div className="input mb-3 gap-8">
                <label htmlFor={name} className="body-m-bold">
                    {label}
                </label>
                <Select
                    className="select__container mb-3"
                    classNamePrefix="select"
                    defaultValue={
                        name !== 'category'
                            ? selectOptions?.filter(op =>
                                  value.includes(op.value),
                              )
                            : selectOptions?.find(op => value === op.value)
                    }
                    isDisabled={false}
                    isLoading={false}
                    isMulti={name !== 'category'}
                    isClearable
                    isSearchable
                    name={name}
                    options={selectOptions || []}
                    onChange={setArrayValue}
                />
            </div>
        );
    }

    return (
        <div
            className={classNames(
                'input d-flex flex-column gap-8',
                className,
                `input-status-${inputState}`,
                {'d-none': name === 'type' || name === '_id'},
            )}>
            {label && type !== 'file' && (
                <label htmlFor={name} className="body-m-bold">
                    {label}
                </label>
            )}
            <div
                className={classNames(
                    'input-container d-flex gap-8 align-items-center border-ui-04',
                    {focused: isFocused},
                    {'d-none': type === 'file'},
                )}>
                <input
                    className="input-element w-100 border-0 p-0 body-m-normal bg-transparent"
                    name={name}
                    id={name}
                    onChange={onChange}
                    value={type === 'file' ? undefined : value}
                    type={showPassword ? 'text' : type}
                    placeholder={placeholder}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    accept={type === 'file' ? 'image/*' : undefined}
                />
                {type === 'password' && (
                    <Button
                        type="button"
                        className={classNames(
                            'border-0 p-0 bg-transparent color-text-03',
                            {'color-text-01': isFocused},
                        )}
                        icon={showPassword ? 'faEye' : 'faEyeSlash'}
                        onClick={() => setShowPassword(!showPassword)}
                    />
                )}
            </div>
            {type === 'file' && (
                <div className="d-flex">
                    {typeof value === 'string' && value ? (
                        <img src={value} height="100px" />
                    ) : undefined}
                    <Button
                        icon="faPlus"
                        text={value ? 'Cambiar imagen' : 'Agregar imagen'}
                        type="button"
                        variation="terciary"
                        className="start p-0 mt-3"
                        onClick={handlePicture}
                    />
                </div>
            )}
            {helperMessage && (
                <span className="body-m-normal">{helperMessage}</span>
            )}
        </div>
    );
}
