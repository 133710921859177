// TODO: check memory usage at deploy time
import * as iconsSolid from '@fortawesome/free-solid-svg-icons';
import * as iconsRegular from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';

import '../scss/components/_icon.scss';

const iconSolidMap = new Map(Object.entries(iconsSolid));
const iconRegularMap = new Map(Object.entries(iconsRegular));

export type IconType = keyof typeof iconsSolid;

interface IconProps {
    icon: IconType;
    size?: '24' | '36' | '48';
    regular?: boolean;
    className?: string;
}

export default function Icon({
    icon,
    size = '24',
    regular = false,
    className,
}: IconProps): JSX.Element {
    return (
        <FontAwesomeIcon
            color=""
            className={classNames(`icon__${size}`, className)}
            icon={
                regular
                    ? (iconRegularMap.get(icon) as IconProp)
                    : (iconSolidMap.get(icon) as IconProp)
            }
        />
    );
}
