import {useContext, useState} from 'react';
import {useNavigate} from 'react-router';
import {Col, Container, Row} from 'react-bootstrap';

import {AuthContext} from '../context/AuthContext';
import {useForm} from '../hooks/useForm';
import Input, {InputStateType} from './forms/inputs/Input';
import Button from './Button';
import {postLogin} from '../utils/api';

import '../scss/components/_login.scss';

export default function Login() {
    const {values, handleInputChange} = useForm({userName: '', password: ''});
    const [inputStatuses, setInputStatus] = useState<{
        userName: InputStateType;
        password: InputStateType;
    }>({userName: 'normal', password: 'normal'});
    const [helper, setHelper] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {login, logout} = useContext(AuthContext);
    const navigate = useNavigate();

    const onLoginSubmitHandle = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const {user, jwt} = await postLogin(values);
            login(user, jwt);
            navigate('/dashboard/users');
            // Needs fix "Any"
        } catch (error: any) {
            setInputStatus({userName: 'error', password: 'error'});
            setHelper(error.response.data.msj);
            logout();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="home-container d-flex">
            <Container className="my-3">
                <Row>
                    {isLoading ? (
                        'Loading..'
                    ) : (
                        <Col
                            xs={12}
                            md={{span: 8, offset: 2}}
                            lg={{span: 6, offset: 3}}
                            xl={{span: 4, offset: 4}}>
                            <form
                                className="login-container"
                                onSubmit={onLoginSubmitHandle}>
                                <h2 className="mb-3 color-text-04 login-title">
                                    Inicio de sesión{' '}
                                </h2>
                                <h3 className="subtitle-m mb-5">
                                    Te damos la bienvenida
                                </h3>
                                <Input
                                    inputState={inputStatuses.userName}
                                    name="userName"
                                    onChange={handleInputChange}
                                    value={values.userName}
                                    label="Nombre de usuario"
                                    className="mb-5"
                                />
                                <Input
                                    inputState={inputStatuses.password}
                                    name="password"
                                    onChange={handleInputChange}
                                    value={values.password}
                                    label="Contraseña"
                                    type="password"
                                    className="mb-5"
                                    helperMessage={helper}
                                />
                                <Button
                                    className="w-100"
                                    text="Iniciar sesión"
                                    disabled={
                                        values.password.trim() === '' ||
                                        values.userName.trim() === ''
                                    }
                                    type="submit"
                                />
                            </form>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    );
}
