import axios from 'axios';
import {getSignature} from './api';

export const imgUpload = async (file: any, section: string) => {
    if (!file) {
        return null;
    }
    const {apiKey, folder, signature, timestamp, uploadPreset} =
        await getSignature(section);
    const cloudUrl = `https://api.cloudinary.com/v1_1/dihpwnm4d/image/upload?api_key=${apiKey}&timestamp=${timestamp}&signature=${signature}`;
    const formData = new FormData();
    formData.append('upload_preset', uploadPreset);
    formData.append('folder', folder);
    formData.append('file', file);

    try {
        const resp = await axios.post(cloudUrl, formData);
        if (resp.data.asset_id) {
            return resp.data.secure_url;
        } else {
            return null;
        }
    } catch (error) {
        throw error;
    }
};
