import {AppRouter} from './router/AppRouter';

import AuthProvider from './context/AuthContext';
import DataProvider from './context/DataContext';
import AlertProvider from './context/AlertContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/styles.scss';

function App(): JSX.Element {
    return (
        <AlertProvider>
            <AuthProvider>
                <DataProvider>
                    <AppRouter />
                </DataProvider>
            </AuthProvider>
        </AlertProvider>
    );
}

export default App;
