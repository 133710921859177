import {useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

import LangSelector from './LangSelector';
import FormSwitched from './forms/FormSwitched';

function InfoLayout() {
    const [lang, setLang] = useState('es');

    return (
        <>
            <Container className="my-4">
                <Row>
                    <LangSelector setLang={setLang} />
                </Row>
            </Container>
            <Container className="my-4">
                <Row>
                    <Col xl={6} lg={8} md={12} xs={12}>
                        <FormSwitched lang={lang} />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default InfoLayout;
