import {useContext, useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Navigate,
    Routes,
    Route,
} from 'react-router-dom';

import {AuthContext} from '../context/AuthContext';
import {useMediaQuery} from '../hooks/useMediaQuery';
import Header from '../components/Header';
import Login from '../components/Login';
import UsersForm from '../components/forms/UsersForm';
import InfoDashboard from '../components/InfoDashboard';
import ConfigForm from '../components/forms/ConfigForm';
import Footer from '../components/Footer';
import Aside from '../components/Aside';
import Alert from '../components/Alert';

import '../scss/components/_view.scss';
import Messages from '../components/Messages';

export const AppRouter = (): JSX.Element => {
    const {user, logout} = useContext(AuthContext);
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const [showAside, setShowAside] = useState(false);

    useEffect(() => {
        setShowAside(isDesktop);
    }, [isDesktop]);

    const handleMenuMobile = () => setShowAside(!showAside);

    return (
        <Router>
            <div className="view__container">
                <Header
                    user={user}
                    logout={logout}
                    isDesktop={isDesktop}
                    handleMenuMobile={handleMenuMobile}
                />
                <div className={user ? 'd-flex' : undefined}>
                    {user && showAside ? (
                        <Aside
                            show={showAside}
                            isDesktop={isDesktop}
                            logout={logout}
                        />
                    ) : undefined}
                    <Alert />
                    <Routes>
                        {user ? (
                            <>
                                <Route
                                    path="/dashboard/users"
                                    element={<UsersForm />}
                                />
                                <Route
                                    path="/dashboard/data/:layout/:section"
                                    element={<InfoDashboard />}
                                />
                                <Route
                                    path="/dashboard/data/:layout/:section/:id"
                                    element={<InfoDashboard />}
                                />
                                <Route
                                    path="/dashboard/message"
                                    element={<Messages />}
                                />
                                <Route
                                    path="/dashboard/config"
                                    element={<ConfigForm />}
                                />
                                <Route
                                    path="*"
                                    element={
                                        <Navigate
                                            to="/dashboard/users"
                                            replace
                                        />
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <Route path="/" element={<Login />} />
                                <Route
                                    path="*"
                                    element={<Navigate to="/" replace />}
                                />
                            </>
                        )}
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
};
