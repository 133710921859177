import {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

import {AlertContext} from '../context/AlertContext';

import {getMessages, putReadMessage} from '../utils/api';

import '../scss/components/_backoffice.scss';
import '../scss/components/_messages.scss';
import moment from 'moment';
import Icon from './Icon';
import {serialize} from 'v8';
import {Link} from 'react-router-dom';
import {useMediaQuery} from '../hooks/useMediaQuery';
import Button from './Button';

interface Message {
    _id: string;
    email: string;
    subject: string;
    body: string;
    read: boolean;
    createdAt: Date;
}

function Messages() {
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const [active, setActive] = useState<Message | null>(null);
    const isMobile = useMediaQuery('(max-width: 1200px)');
    const {createAlert} = useContext(AlertContext);

    useEffect(() => {
        setIsLoading(true);
        getMessages()
            .then(data => setMessages(data.items))
            .catch(error => {
                createAlert({
                    type: 'error',
                    title: 'Mensajes',
                    text: error.data.msg || JSON.stringify(error),
                });
            })
            .finally(() => setIsLoading(false));
        return () => setActive(null);
    }, []);

    const setRead = (id: string) => {
        putReadMessage(id)
            .then(data => {
                setActive(data.message);
                setMessages(
                    messages.map(msg =>
                        msg._id === data.message._id ? data.message : msg,
                    ),
                );
            })
            .catch(error => {
                createAlert({
                    type: 'error',
                    title: 'Mensajes',
                    text: error.data.msg || JSON.stringify(error),
                });
            });
    };

    return (
        <main className="layout__container">
            <Container className="my-5">
                <Row>
                    <Col xs={12}>
                        <h2 className="mb-3">Mensajes</h2>
                    </Col>
                </Row>
            </Container>
            <Container className="my-5">
                <Row>
                    {!isLoading && ((isMobile && !active) || !isMobile) ? (
                        <Col
                            xs={12}
                            md={8}
                            lg={5}
                            className="messages__list-container">
                            {messages.map(mesg => (
                                <div
                                    key={mesg._id}
                                    role="button"
                                    className={`messages__list-item${
                                        mesg._id === active?._id
                                            ? ' active'
                                            : ''
                                    }${mesg.read ? ' read' : ''}`}
                                    onClick={() => setRead(mesg._id)}>
                                    <div className="d-flex align-items-center">
                                        <Icon
                                            icon={
                                                mesg.read
                                                    ? 'faEnvelopeOpen'
                                                    : 'faEnvelope'
                                            }
                                            className="icons__24 color-text"
                                        />
                                        <div className="ml-3">
                                            <span>{mesg.email}</span>
                                            <br />
                                            <span>{mesg.subject}</span>
                                        </div>
                                    </div>
                                    <span>
                                        {moment(mesg.createdAt).format(
                                            'DD MMM YY',
                                        )}
                                    </span>
                                </div>
                            ))}
                        </Col>
                    ) : undefined}
                    {active ? (
                        <Col xs={12} md={8} lg={7}>
                            <div className="d-flex space-between">
                                <Button
                                    icon="faArrowLeft"
                                    variation="terciary"
                                    onClick={() => setActive(null)}
                                />
                                <Button
                                    icon="faReply"
                                    variation="terciary"
                                    text="Responder"
                                    onClick={() =>
                                        (window.location.href =
                                            'mailto:marcos@gmail.com')
                                    }
                                />
                            </div>
                            <div className="messages__data-container space-between">
                                <div className="d-flex">
                                    <span>email:</span>
                                    <p className="ml-2">{active.email}</p>
                                </div>
                                <p>
                                    {moment(active.createdAt).format(
                                        'DD MMM YY hh:mm:ss',
                                    )}
                                </p>
                            </div>
                            <div className="messages__data-container">
                                <span>Asunto:</span>
                                <p className="ml-2">{active.subject}</p>
                            </div>
                            <div className="messages__body-container">
                                <p>{active.body}</p>
                            </div>
                        </Col>
                    ) : undefined}
                </Row>
            </Container>
        </main>
    );
}

export default Messages;
