import {Col, Container, Row} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router';

import Button from './Button';
import ChipsGrid from './ChipsGrid';

import '../scss/components/_backoffice.scss';

interface ListProps {
    listOf: string;
}

function List({listOf}: ListProps) {
    const navigate = useNavigate();
    const {section} = useParams();

    return (
        <Container className="mt-3">
            <Row className="my-3">
                <Col xs={12}>
                    <Button
                        icon="faPlus"
                        text={`Agregar ${listOf.toLocaleLowerCase()}`}
                        type="button"
                        variation="terciary"
                        className="p-0"
                        onClick={() =>
                            navigate(`/dashboard/data/edit/${section}`)
                        }
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={4} lg={6} md={12}>
                    <ChipsGrid />
                </Col>
            </Row>
        </Container>
    );
}

export default List;
