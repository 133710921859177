import {useContext} from 'react';
import {useNavigate, useParams} from 'react-router';

import Button from './Button';

import '../scss/components/_backoffice.scss';
import {DataContext, DataState} from '../context/DataContext';

function ChipsGrid() {
    const navigate = useNavigate();
    const {section} = useParams();
    const data = useContext(DataContext);

    return (
        <div className="layout__chips-container flex-wrap">
            {section
                ? data[section as keyof DataState]?.map(data => (
                      <Button
                          key={data._id}
                          text={data.name}
                          type="button"
                          variation="secondary"
                          className="m-2 w-100"
                          onClick={() =>
                              navigate(
                                  `/dashboard/data/edit/${section}/${data._id}`,
                              )
                          }
                      />
                  ))
                : undefined}
        </div>
    );
}

export default ChipsGrid;
