import {useContext, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import classNames from 'classnames';

import Icon from './Icon';

import '../scss/components/_sidebar.scss';
import {AuthContext} from '../context/AuthContext';

interface AsideProps {
    show: boolean;
    isDesktop: boolean;
    logout: () => void;
}

function Aside({show, isDesktop, logout}: AsideProps) {
    const {user} = useContext(AuthContext);
    const [activeLink, setActiveLink] = useState('');
    const navigate = useNavigate();
    const {pathname} = useLocation();

    useEffect(() => {
        setActiveLink(`${pathname.substring(1)}`);
    }, [pathname]);

    return (
        <aside
            className={classNames('aside__width ', {
                'sidebar-width-open': show === true,
                'sidebar-width-close': show === false,
            })}>
            <div className="sidebar__container">
                <div className="sidebar">
                    <div className="d-flex flex-direction-column ">
                        {user?.userType.includes('admin') && (
                            <Link
                                to="/dashboard/users"
                                className={classNames(
                                    'navbar__link-div  px-4 py-3 my-2 ',
                                    {
                                        'link-active':
                                            activeLink === 'dashboard/users',
                                    },
                                )}>
                                <div className=" d-flex flex-nowrap text-nowrap align-items-center ">
                                    <Icon
                                        icon="faUser"
                                        className="icons__24 color-text"
                                    />
                                    <p
                                        className={`mb-0 pl-2 mr-auto ${
                                            activeLink === 'dashboard/users'
                                                ? 'body-s-sb'
                                                : 'body-s'
                                        }`}>
                                        Usuarios
                                    </p>
                                </div>
                            </Link>
                        )}
                        {user?.userType.includes('user') && (
                            <>
                                <Link
                                    to="/dashboard/data/list/drinks"
                                    className={classNames(
                                        'navbar__link-div  px-4 py-3 my-2 ',
                                        {
                                            'link-active':
                                                activeLink.includes('drinks'),
                                        },
                                    )}>
                                    <div className=" d-flex flex-nowrap text-nowrap align-items-center ">
                                        <Icon
                                            icon="faMartiniGlassCitrus"
                                            className="icons__24  color-text "
                                        />
                                        <p
                                            className={`mb-0 pl-2 mr-auto ${
                                                activeLink.includes('drinks')
                                                    ? 'body-s-sb'
                                                    : 'body-s'
                                            }`}>
                                            Tragos con Alcohol
                                        </p>
                                    </div>
                                </Link>
                                <Link
                                    to="/dashboard/data/list/juices"
                                    className={classNames(
                                        'navbar__link-div px-4 py-3 my-2 ',
                                        {
                                            'link-active':
                                                activeLink.includes('juices'),
                                        },
                                    )}>
                                    <div className=" d-flex flex-nowrap text-nowrap align-items-center ">
                                        <Icon
                                            icon="faGlassWater"
                                            className="icons__24 color-text "
                                        />
                                        <p
                                            className={`mb-0 pl-2 mr-auto ${
                                                activeLink.includes('juices')
                                                    ? 'body-s-sb'
                                                    : 'body-s'
                                            }`}>
                                            Jugos y licuados
                                        </p>
                                    </div>
                                </Link>
                                <Link
                                    to="/dashboard/data/list/coffees"
                                    className={classNames(
                                        'navbar__link-div  px-4 py-3 my-2 ',
                                        {
                                            'link-active':
                                                activeLink.includes('coffees'),
                                        },
                                    )}>
                                    <div className=" d-flex flex-nowrap text-nowrap align-items-center ">
                                        <Icon
                                            icon="faMugHot"
                                            className="icons__24 color-text "
                                        />
                                        <p
                                            className={`mb-0 pl-2 mr-auto ${
                                                activeLink.includes('coffees')
                                                    ? 'body-s-sb'
                                                    : 'body-s'
                                            }`}>
                                            Cafetería
                                        </p>
                                    </div>
                                </Link>
                                <Link
                                    to="/dashboard/data/list/ingredients"
                                    className={classNames(
                                        'navbar__link-div  px-4 py-3 my-2 ',
                                        {
                                            'link-active':
                                                activeLink.includes(
                                                    'ingredients',
                                                ),
                                        },
                                    )}>
                                    <div className="d-flex flex-nowrap text-nowrap align-items-center">
                                        <Icon
                                            icon="faUser"
                                            className="icons__24 color-text "
                                        />
                                        <p
                                            className={`mb-0 pl-2 mr-auto ${
                                                activeLink ===
                                                'dashboard/list/ingredients'
                                                    ? 'body-s-sb'
                                                    : 'body-s'
                                            }`}>
                                            Ingredientes
                                        </p>
                                    </div>
                                </Link>
                                <Link
                                    to="/dashboard/data/list/tools"
                                    className={classNames(
                                        'navbar__link-div  px-4 py-3 my-2 ',
                                        {
                                            'link-active':
                                                activeLink.includes('tools'),
                                        },
                                    )}>
                                    <div className=" d-flex flex-nowrap text-nowrap align-items-center ">
                                        <Icon
                                            icon="faMartiniGlass"
                                            className="icons__24 color-text"
                                        />
                                        <p
                                            className={`mb-0 pl-2 mr-auto ${
                                                activeLink.includes('tools')
                                                    ? 'body-s-sb'
                                                    : 'body-s'
                                            }`}>
                                            Utensilios
                                        </p>
                                    </div>
                                </Link>
                                <Link
                                    to="/dashboard/message"
                                    className={classNames(
                                        'navbar__link-div  px-4 py-3 my-2 ',
                                        {
                                            'link-active':
                                                activeLink.includes('message'),
                                        },
                                    )}>
                                    <div className=" d-flex flex-nowrap text-nowrap align-items-center ">
                                        <Icon
                                            icon="faEnvelope"
                                            className="icons__24 color-text"
                                        />
                                        <p
                                            className={`mb-0 pl-2 mr-auto ${
                                                activeLink.includes('message')
                                                    ? 'body-s-sb'
                                                    : 'body-s'
                                            }`}>
                                            Mensajes
                                        </p>
                                    </div>
                                </Link>
                                <Link
                                    to="/dashboard/config"
                                    className={classNames(
                                        'navbar__link-div  px-4 py-3 my-2 ',
                                        {
                                            'link-active':
                                                activeLink.includes('config'),
                                        },
                                    )}>
                                    <div className=" d-flex flex-nowrap text-nowrap align-items-center ">
                                        <Icon
                                            icon="faGear"
                                            className="icons__24 color-text"
                                        />
                                        <p
                                            className={`mb-0 pl-2 mr-auto ${
                                                activeLink.includes('config')
                                                    ? 'body-s-sb'
                                                    : 'body-s'
                                            }`}>
                                            Configuración
                                        </p>
                                    </div>
                                </Link>
                            </>
                        )}
                        <hr className={classNames('ml-3 pr-1')} />
                        {!isDesktop ? (
                            <button
                                onClick={() => {
                                    logout();
                                    navigate('/');
                                }}
                                className="d-flex align-items-center color-interactive-04 bg-transparent border-0 logout-button mt-7 ml-4">
                                <Icon icon="faArrowRightFromBracket" />
                                <span className="body-s-bold">
                                    Cerrar sesión
                                </span>
                            </button>
                        ) : undefined}
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default Aside;
