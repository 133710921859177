import '../scss/components/_button.scss';
import classNames from 'classnames';
import Icon, {IconType} from './Icon';

interface ButtonProps {
    text?: string;
    onClick?: () => void;
    variation?: 'primary' | 'secondary' | 'terciary';
    size?: 'm' | 'l';
    icon?: IconType;
    iconRight?: boolean;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
    className?: string;
}

export default function Button({
    text,
    variation = 'primary',
    size = 'm',
    icon,
    iconRight = false,
    className,
    ...props
}: ButtonProps) {
    return (
        <button
            className={classNames(
                'button d-flex center-box gap-8',
                `button__${variation} button-${size} button-${size}-sb`,
                {'flex-row-reverse': iconRight},
                {button__square: !text},
                className,
            )}
            {...props}>
            {icon && (
                <Icon icon={icon} size={text || size === 'm' ? '24' : '36'} />
            )}
            {text}
        </button>
    );
}
