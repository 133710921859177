import {Col, Container, Row} from 'react-bootstrap';
import pck from '../../package.json';

import '../scss/components/_footer.scss';

export default function Footer(): JSX.Element {
    return (
        <footer className="footer-container">
            <Container>
                <Row className="footer-row">
                    <Col
                        md={4}
                        sm={12}
                        className="d-flex flex-direction-row flex-no-wrap justify-content-start align-items-center">
                        <p className="text-nowrap mb-0 highlight-3-regular">
                            Seed Club - 2024 - v{pck.version}
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
