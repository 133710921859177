import {createContext, ReactNode, useState} from 'react';

export interface AlertState {
    type: AlertTypes;
    title: string;
    text: string;
}

export interface AlertContextProps {
    alert: {
        type: AlertTypes;
        title: string;
        text: string;
    } | null;
    createAlert: (data: AlertState) => void;
    cleanAlert: () => void;
}

export type AlertTypes = 'error' | 'success' | 'warning' | 'info';

export type Types = 'create' | 'clean';

const inicialState: AlertState | null = null;

export const AlertContext = createContext<AlertContextProps>(
    {} as AlertContextProps,
);

export default function AlertProvider({children}: {children: ReactNode}) {
    const [alert, setAlert] = useState(inicialState);

    const createAlert = (data: AlertState) => {
        setAlert(data);
        setTimeout(() => {
            cleanAlert();
        }, 5000);
    };

    const cleanAlert = () => setAlert(inicialState);

    const data = {alert, createAlert, cleanAlert};

    return (
        <AlertContext.Provider value={data}>{children}</AlertContext.Provider>
    );
}
