import {useContext, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

import Button from '../Button';
import Input, {InputStateType} from './inputs/Input';

import {useForm} from '../../hooks/useForm';
import {postCreateUser} from '../../utils/api';

import '../../scss/components/_backoffice.scss';
import {ActionMeta, MultiValue, SingleValue} from 'react-select';
import {AlertContext} from '../../context/AlertContext';

const userTypes = [
    {
        value: 'user',
        label: 'User',
    },
    {
        value: 'admin',
        label: 'Admin',
    },
    {
        value: 'tech',
        label: 'Tech',
    },
];

function UsersForm() {
    const {values, handleInputChange, setValues, resetForm} = useForm({
        userName: '',
        email: '',
        password: '',
        userType: ['user'],
    });
    const [inputStatus, setInputStatus] = useState<{
        userName: InputStateType;
        email: InputStateType;
        password: InputStateType;
        userType: InputStateType;
    }>({
        userName: 'normal',
        email: 'normal',
        password: 'normal',
        userType: 'normal',
    });
    const [helper, setHelper] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {createAlert} = useContext(AlertContext);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const data = await postCreateUser(values);
            createAlert({
                type: 'success',
                title: 'Usuarios',
                text: data.msg,
            }),
                resetForm();
            // Needs fix "Any" -> AxiosResponse
        } catch (error: any) {
            setInputStatus({
                ...inputStatus,
                userName: 'error',
                email: 'error',
                password: 'error',
            });
            setHelper(error.data.msj);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <main className="layout__container">
            <Container className="my-5">
                <Row>
                    <Col xs={12}>
                        <h2 className="mb-3">Usuarios</h2>
                        <Button
                            icon="faUserPlus"
                            text="Agregar usuario"
                            type="button"
                            variation="terciary"
                            className="p-0"
                            onClick={() => setShowForm(true)}
                        />
                    </Col>
                </Row>
            </Container>

            {showForm ? (
                <Container className="my-3">
                    <Row>
                        {isLoading ? (
                            'Loading..'
                        ) : (
                            <Col lg={4} md={6} sm={12}>
                                <form onSubmit={onSubmit}>
                                    <Input
                                        inputState={inputStatus.userName}
                                        name="userName"
                                        onChange={handleInputChange}
                                        value={values.userName}
                                        label="Nombre de usuario"
                                        className="mb-3"
                                    />
                                    <Input
                                        inputState={inputStatus.email}
                                        name="email"
                                        onChange={handleInputChange}
                                        value={values.email}
                                        label="Email"
                                        className="mb-3"
                                    />
                                    <Input
                                        inputState={inputStatus.userType}
                                        name="userType"
                                        type="array"
                                        onChange={handleInputChange}
                                        setArrayValue={(
                                            newValue:
                                                | SingleValue<{
                                                      value: string;
                                                      label: string;
                                                  }>
                                                | MultiValue<{
                                                      value: string;
                                                      label: string;
                                                  }>,
                                            actionMeta: ActionMeta<{
                                                value: string;
                                                label: string;
                                            }>,
                                        ) => {
                                            if (!Array.isArray(newValue)) {
                                                const val: {
                                                    value: string;
                                                    label: string;
                                                } = newValue as {
                                                    value: string;
                                                    label: string;
                                                };
                                                setValues({
                                                    ...values,
                                                    [actionMeta.name as string]:
                                                        val?.value || '',
                                                });
                                            } else {
                                                setValues({
                                                    ...values,
                                                    [actionMeta.name as string]:
                                                        newValue.map(
                                                            op => op.value,
                                                        ),
                                                });
                                            }
                                        }}
                                        value={values.userType}
                                        selectOptions={userTypes}
                                        label="Permisos"
                                        className="mb-3"
                                    />
                                    <Input
                                        inputState={inputStatus.password}
                                        name="password"
                                        onChange={handleInputChange}
                                        value={values.password}
                                        label="Contraseña"
                                        type="password"
                                        className="mb-5"
                                        helperMessage={helper}
                                    />
                                    <Button
                                        className="w-100"
                                        text="Crear usuario"
                                        disabled={
                                            values.password.trim().length < 4 ||
                                            values.userName.trim().length < 4
                                        }
                                        type="submit"
                                    />
                                </form>
                            </Col>
                        )}
                    </Row>
                </Container>
            ) : undefined}
        </main>
    );
}

export default UsersForm;
