import {createContext, ReactNode, useEffect, useReducer} from 'react';
import {getRevalidate, instance} from '../utils/api';
import {User} from '../utils/types';

type AuthAction =
    | {
          type: 'login';
          payload: {user: User; token: string | null};
      }
    | {
          type: 'logout';
          payload: {user: null; token: null};
      };

interface AuthState {
    user: User | null;
    token: string | null;
}

const authInicialState: AuthState = {
    user: null,
    token: null,
};

const authReducer = (state: AuthState, action: AuthAction) => {
    const {type, payload} = action;

    switch (type) {
        case 'login':
            return {
                ...state,
                user: payload.user,
            };
        case 'logout':
            return {
                ...state,
                user: payload.user,
            };
        default:
            return state;
    }
};

interface AuthContextProps {
    user: User | null;
    login: (user: User, token: string) => void;
    logout: () => void;
    token: string | null;
}
export const AuthContext = createContext<AuthContextProps>(
    {} as AuthContextProps,
);

export default function AuthProvider({children}: {children: ReactNode}) {
    const [state, dispatch] = useReducer(authReducer, authInicialState);

    useEffect(() => {
        (async () => {
            const token = localStorage.getItem('token');
            if (token) {
                instance.defaults.headers.common['Authorization'] = token
                    ? `Bearer ${token}`
                    : '';
                getRevalidate()
                    .then(({user, jwt}) => login(user, jwt))
                    .catch(() => logout());
            } else {
                logout();
            }
        })();
    }, []);

    const login = (user: User, token: string) => {
        instance.defaults.headers.common['Authorization'] = token
            ? `Bearer ${token}`
            : '';
        localStorage.setItem('token', token);
        dispatch({
            type: 'login',
            payload: {user, token},
        });
    };

    const logout = () => {
        localStorage.removeItem('token');
        dispatch({
            type: 'logout',
            payload: {user: null, token: null},
        });
    };

    const data = {...state, login, logout};

    return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
}
