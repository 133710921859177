import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';
import {DataKeys, getData} from '../utils/api';
import {AuthContext} from './AuthContext';

export interface Data {
    _id: string;
    name: string;
}

export interface DataState {
    coffees?: Data[];
    drinks?: Data[];
    juices?: Data[];
    ingredients?: Data[];
    tools?: Data[];
}

export interface DataContextProps {
    coffees?: Data[];
    drinks?: Data[];
    juices?: Data[];
    ingredients?: Data[];
    tools?: Data[];
    updateData: (data: DataState, type: Types) => void;
}

export type Types =
    | 'getCoffees'
    | 'getDrinks'
    | 'getJuices'
    | 'getIngredients'
    | 'getTools'
    | 'updateCoffees'
    | 'updateDrinks'
    | 'updateJuices'
    | 'updateIngredients'
    | 'updateTools';

type DataAction = {
    type: Types;
    payload: DataState;
};

const dataInicialState: DataState = {
    coffees: [],
    drinks: [],
    juices: [],
    ingredients: [],
    tools: [],
};

const dataReducer = (state: DataState, action: DataAction) => {
    const {type, payload} = action;

    switch (type) {
        case 'getCoffees':
            return {
                ...state,
                coffees: payload.coffees,
            };
        case 'getDrinks':
            return {
                ...state,
                drinks: payload.drinks,
            };
        case 'getJuices':
            return {
                ...state,
                juices: payload.juices,
            };
        case 'getIngredients':
            return {
                ...state,
                ingredients: payload.ingredients,
            };
        case 'getTools':
            return {
                ...state,
                tools: payload.tools,
            };
        case 'updateCoffees':
            return {
                ...state,
                coffees: [
                    ...(state.coffees || []),
                    ...(payload?.coffees || []),
                ],
            };
        case 'updateDrinks':
            return {
                ...state,
                drinks: [...(state.drinks || []), ...(payload?.drinks || [])],
            };
        case 'updateJuices':
            return {
                ...state,
                juices: [...(state.juices || []), ...(payload?.juices || [])],
            };
        case 'updateIngredients':
            return {
                ...state,
                ingredients: [
                    ...(state.ingredients || []),
                    ...(payload?.ingredients || []),
                ],
            };
        case 'updateTools':
            return {
                ...state,
                tools: [...(state.tools || []), ...(payload?.tools || [])],
            };
        default:
            return state;
    }
};

export const DataContext = createContext<DataContextProps>(
    {} as DataContextProps,
);

export default function DataProvider({children}: {children: ReactNode}) {
    const [state, dispatch] = useReducer(dataReducer, dataInicialState);
    const {user} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (user?.userType.includes('user')) {
                setLoading(true);
                await Promise.all(
                    (Object.keys(dataInicialState) as DataKeys[]).map(
                        async (key: DataKeys, i) => {
                            const data = await getData(key);
                            const objKey =
                                key === 'tools' || key === 'ingredients'
                                    ? key
                                    : 'recipes';
                            setData(
                                {[key]: data[objKey]},
                                `get${
                                    key[0].toUpperCase() + key.slice(1)
                                }` as Types,
                                key,
                            );
                            if (
                                i ===
                                Object.keys(dataInicialState).length - 1
                            ) {
                                setLoading(false);
                            }
                        },
                    ),
                );
            }
        })();
    }, [user]);

    const setData = (data: DataState, type: Types, prop: keyof DataState) => {
        dispatch({
            type,
            payload: {
                [prop]: data[prop]?.map(dt => ({_id: dt._id, name: dt.name})),
            },
        });
    };

    const updateData = (data: DataState, type: Types) => {
        dispatch({
            type,
            payload: data,
        });
    };

    const data = {...state, updateData, loading};

    return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
}
