import {Col, Container, Row} from 'react-bootstrap';
import {useParams} from 'react-router';

import InfoLayout from './InfoLayout';
import List from './List';

import '../scss/components/_backoffice.scss';
import {useMediaQuery} from '../hooks/useMediaQuery';

interface Titles {
    [key: string]: string;
}

const titles: Titles = {
    drinks: 'Tragos con alcohol',
    juices: 'Jugos y licuados',
    coffees: 'Cafetería',
    ingredients: 'Ingredientes',
    tools: 'Utensilios',
    default: '',
};

function InfoDashboard() {
    const {layout, section} = useParams();
    const isDesktop = useMediaQuery('(min-width: 768px)');

    return (
        <main className={`layout__container${isDesktop ? ' p-5' : ''}`}>
            <Container className="layout__info-container my-2">
                <Row>
                    <Col xs={12}>
                        <h2>{(titles as Titles)[section || 'default']}</h2>
                    </Col>
                </Row>
            </Container>
            {layout === 'edit' ? <InfoLayout /> : undefined}
            {layout === 'list' ? (
                <List listOf={(titles as Titles)[section || 'default']} />
            ) : undefined}
        </main>
    );
}

export default InfoDashboard;
