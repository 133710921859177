import {ChangeEvent, useContext, useEffect, useState} from 'react';
import Select, {SingleValue} from 'react-select';
import classNames from 'classnames';

import {DataContext} from '../../../context/DataContext';
import {useMediaQuery} from '../../../hooks/useMediaQuery';
import Button from '../../Button';

import {IngredientAndQuantity} from '../../../utils/types';

const INITIAL_VALUE: IngredientAndQuantity = {
    quantity: 0,
    unit: '',
    ingredient: {
        _id: '',
        name: '',
        description: '',
        flavor: '',
        category: '',
        origin: '',
        img: '',
    },
};

const UNITS_VALUES = [
    {value: 'cn', label: 'C/N'},
    {value: 'tablespoons', label: 'Cucharadas'},
    {value: 'ml', label: 'ml'},
    {value: 'oz', label: 'Oz'},
    {value: 'slices', label: 'Rodajas'},
    {value: 'un', label: 'Unidades'},
    {value: 'gr', label: 'gr'},
];

interface IngredientsInputsProps {
    values: string;
    onChange: (values: {value: string; label: string}[]) => void;
}

function IngredientsInputs({values, onChange}: IngredientsInputsProps) {
    const isDesktop = useMediaQuery('(min-width: 768px)');
    const {ingredients: ingredientsData} = useContext(DataContext);
    const options =
        ingredientsData?.map(tool => ({value: tool._id, label: tool.name})) ||
        [];
    const [isFocused, setIsFocused] = useState<number | null>(null);
    const [ingredients, setIngredients] = useState<IngredientAndQuantity[]>([
        INITIAL_VALUE,
    ]);

    const addStep = () => {
        setIngredients([...ingredients, INITIAL_VALUE]);
    };

    const deleteStep = (stepIndex: number) => {
        setIngredients(ingredients.filter((s, i) => i !== stepIndex));
    };

    const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        setIngredients(
            ingredients.map((ingredient, i) => {
                if (e.target.id === i.toString()) {
                    return {...ingredient, [e.target.name]: e.target.value};
                } else {
                    return ingredient;
                }
            }),
        );
    };

    const onChangeSelect = async (
        val: SingleValue<{value: string; label: string}>,
        index: number,
        name: string,
    ) => {
        setIngredients(
            ingredients.map((step, i) => {
                if (index === i) {
                    return {...step, [name]: val?.value};
                } else {
                    return step;
                }
            }),
        );
    };

    useEffect(() => {
        onChange(
            ingredients.map(ing => ({
                value: JSON.stringify(ing),
                label: JSON.stringify(ing),
            })),
        );
    }, [ingredients]);

    useEffect(() => {
        if (values.length && typeof values === 'string') {
            setIngredients(JSON.parse(values));
        }
    }, []);

    return (
        <>
            {ingredients.map((ingredient, i) => (
                <div
                    className={classNames({'d-flex': isDesktop}, 'my-4')}
                    key={i}>
                    <div
                        className={classNames('d-flex my-3 mr-3 gap-2 w-30', {
                            'w-100': !isDesktop,
                        })}>
                        <div
                            className={classNames(
                                'input-container d-flex gap-8 border-ui-04 align-items-center w-30',
                                {focused: i === isFocused},
                            )}>
                            <input
                                className="input-element w-100 border-0 p-0 body-m-normal bg-transparent"
                                name="quantity"
                                id={i.toString()}
                                onChange={handleInputChange}
                                value={ingredient.quantity}
                                type="number"
                                onFocus={() => setIsFocused(i)}
                                onBlur={() => setIsFocused(null)}
                            />
                        </div>
                        <Select
                            className="select__container w-70"
                            classNamePrefix="select"
                            defaultValue={UNITS_VALUES.find(
                                unit => unit.value === ingredient.unit,
                            )}
                            value={UNITS_VALUES.find(
                                unit => unit.value === ingredient.unit,
                            )}
                            isDisabled={false}
                            isSearchable
                            name="unit"
                            options={UNITS_VALUES}
                            onChange={val => onChangeSelect(val, i, 'unit')}
                        />
                    </div>
                    <div
                        className={classNames('d-flex my-3 gap-2 w-70', {
                            'w-100': !isDesktop,
                        })}>
                        <Select
                            className="select__container w-100"
                            classNamePrefix="select"
                            defaultValue={options.find(
                                op => op.value === ingredient.ingredient,
                            )}
                            value={options.find(
                                op => op.value === ingredient.ingredient,
                            )}
                            isDisabled={false}
                            isSearchable
                            name="ingredient"
                            options={options}
                            onChange={val =>
                                onChangeSelect(val, i, 'ingredient')
                            }
                        />
                        <div
                            className={classNames('d-flex align-items-center')}>
                            <Button
                                icon="faMinus"
                                type="button"
                                variation="terciary"
                                className="start p-0"
                                onClick={() => deleteStep(i)}
                            />
                        </div>
                    </div>
                    <div
                        className={classNames('d-flex align-items-center', {
                            'v-hidden': ingredients.length - 1 !== i,
                        })}>
                        <Button
                            icon="faPlus"
                            type="button"
                            variation="terciary"
                            className="start p-0"
                            onClick={addStep}
                        />
                    </div>
                </div>
            ))}
            <div
                className={classNames('d-flex align-items-center', {
                    'v-hidden': ingredients.length,
                })}>
                <Button
                    icon="faPlus"
                    type="button"
                    variation="terciary"
                    className="start p-0"
                    onClick={addStep}
                />
            </div>
        </>
    );
}

export default IngredientsInputs;
