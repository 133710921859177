import {ChangeEvent, useState} from 'react';
import {useParams} from 'react-router';
import {DataState} from '../context/DataContext';
import {imgUpload} from '../utils/imgsUpload';

export const useForm = <T>(initialValue: T) => {
    const [values, setValues] = useState(initialValue);
    const {section} = useParams();

    const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = await imgUpload(
                e.target.files[0],
                section as keyof DataState,
            );
            setValues({
                ...values,
                [e.target.name]: file,
            });
        } else {
            setValues({
                ...values,
                [e.target.name]: e.target.value,
            });
        }
    };

    const setArraysValues = (
        name: string,
        valuesSelected: {value: string; label: string}[],
    ) => {
        setValues({
            ...values,
            [name]: valuesSelected.map(val => {
                if (name === 'ingredients') {
                    return JSON.parse(val.value);
                } else {
                    return val.value;
                }
            }),
        });
    };

    const resetForm = () => setValues(initialValue);

    return {values, handleInputChange, resetForm, setArraysValues, setValues};
};
