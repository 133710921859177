import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';

import {AlertContext} from '../../context/AlertContext';
import {Data, DataContext, DataState, Types} from '../../context/DataContext';
import Form from './Form';

import {Ingredient, Recipes, RecipesTypes, Tool} from '../../utils/types';
import {getById, postCreateData, putEditData} from '../../utils/api';

const INITIAL_STATE: Recipes = {
    name: '',
    type: 'drinks' as unknown as RecipesTypes,
    description: '',
    flavor: '',
    origin: '',
    ingredients: [],
    tools: [],
    steps: [],
    extra_tip: '',
    img: '',
};

function RecipesForm({lang}: {lang: string}) {
    const [initialRecipe, setInitialRecipe] = useState(INITIAL_STATE);
    const [loading, setLoading] = useState(true);
    const {section, id} = useParams();
    const {createAlert} = useContext(AlertContext);
    const {updateData} = useContext(DataContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            getById(section as keyof DataState, id, lang)
                .then(data => {
                    const {_id, createdAt, updatedAt, ...recipe} = data.item;
                    setInitialRecipe({
                        ...INITIAL_STATE,
                        ...recipe,
                        tools: recipe.tools?.map((tool: any) => tool._id),
                        ingredients: recipe.ingredients?.map(
                            (ingredient: any) => ({
                                ...ingredient,
                                ingredient: ingredient.ingredient._id,
                            }),
                        ),
                    });
                })
                .catch(err => {
                    createAlert({
                        type: 'error',
                        title: 'No lo logramos, por ahora..',
                        text: err.data?.msj || err.data?.errors,
                    });
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
    }, [lang]);

    const onSubmit = async (values: Tool | Ingredient | Recipes) => {
        try {
            if (id) {
                const resp = await putEditData(
                    values,
                    section as keyof DataState,
                    lang,
                    id,
                );
                const {name} = resp.item as Data;
                createAlert({
                    type: 'success',
                    title: name,
                    text: resp.msj,
                });
            } else {
                if (section) {
                    const resp = await postCreateData(
                        values,
                        section as keyof DataState,
                        lang,
                    );
                    const {_id, name} = resp.item as Data;
                    updateData(
                        {ingredients: [{_id, name}]},
                        `update${
                            section[0].toUpperCase() + section.slice(1)
                        }` as Types,
                    );
                    navigate(`/dashboard/data/edit/${section}/${_id}`);
                    createAlert({
                        type: 'success',
                        title: 'Receta creado correctamente',
                        text: resp.msj,
                    });
                }
            }
        } catch (error: any) {
            createAlert({
                type: 'error',
                title: 'No lo logramos, por ahora..',
                text: error.data.msj || error.data.errors,
            });
        }
    };

    return loading ? (
        <p>Loading</p>
    ) : (
        <Form
            INITIAL_STATE={{
                ...initialRecipe,
                type: section?.slice(0, -1) as unknown as RecipesTypes,
            }}
            onSubmit={onSubmit}
            categoryOptions={[]}
            disabled={false}
        />
    );
}

export default RecipesForm;
