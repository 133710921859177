import {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Select, {SingleValue} from 'react-select';

import {AlertContext} from '../../context/AlertContext';

import Button from '../Button';
import Input from './inputs/Input';

import {useForm} from '../../hooks/useForm';
import {getAllVars, postCreateVarConfig, putEditVar} from '../../utils/api';

import '../../scss/components/_backoffice.scss';

const scopeTypes = [
    {
        value: 'page',
        label: 'Landingpage',
    },
    {
        value: 'app',
        label: 'Aplicación',
    },
    {
        value: 'bo',
        label: 'Backoffice',
    },
    {
        value: 'server',
        label: 'Servidor',
    },
];

function ConfigForm() {
    const {values, handleInputChange, setValues, resetForm} = useForm({
        key: '',
        scope: 'page',
        value: '',
    });

    const [form, setForm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [varOptions, setVarOptions] = useState<
        SingleValue<{value: string | undefined; label: string}>[]
    >([]);
    const {createAlert} = useContext(AlertContext);

    useEffect(() => {
        if (form === 'edit') {
            getAllVars().then(data => {
                const ops = Object.entries(data.variables).map(
                    ([key, value]) => ({label: key, value}),
                );
                setVarOptions(
                    ops as {value: string | undefined; label: string}[],
                );
            });
        }
    }, [form]);

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            if (form === 'add') {
                const data = await postCreateVarConfig({
                    key: `${values.scope}.${values.key}`,
                    value: values.value,
                });
                createAlert({
                    type: 'success',
                    title: 'Configuración',
                    text: data.msg,
                });
                resetForm();
            }
            if (form === 'edit') {
                const data = await putEditVar({
                    key: `${values.scope}.${values.key}`,
                    value: values.value,
                });
                setVarOptions(
                    varOptions.map(option => {
                        return option?.label === `${values.scope}.${values.key}`
                            ? {
                                  label: `${values.scope}.${values.key}`,
                                  value: values.value,
                              }
                            : option;
                    }),
                );
                createAlert({
                    type: 'success',
                    title: 'Configuración',
                    text: data.msg,
                });
                resetForm();
            }
        } catch (error: any) {
            createAlert({
                type: 'error',
                title: 'Configuración',
                text: error.data.msg || JSON.stringify(error),
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <main className="layout__container">
            <Container className="my-5">
                <Row>
                    <Col xs={12}>
                        <h2 className="mb-3">Configuración</h2>
                        <div className="d-flex">
                            <Button
                                icon="faPlus"
                                text="Agregar variable"
                                type="button"
                                variation="terciary"
                                className="p-0 mr-4"
                                onClick={() => setForm('add')}
                            />
                            <Button
                                icon="faPencil"
                                text="Modificar variable"
                                type="button"
                                variation="terciary"
                                className="p-0"
                                onClick={() => setForm('edit')}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>

            {form ? (
                <Container className="my-3">
                    <Row>
                        {isLoading ? (
                            'Loading..'
                        ) : (
                            <Col lg={4} md={6} sm={12}>
                                <form onSubmit={onSubmit}>
                                    {form === 'add' ? (
                                        <>
                                            <label htmlFor="scope">Scope</label>
                                            <Select
                                                className="select__container mb-3"
                                                classNamePrefix="select"
                                                isDisabled={false}
                                                isSearchable
                                                name="scope"
                                                options={scopeTypes}
                                                onChange={val =>
                                                    setValues({
                                                        ...values,
                                                        scope:
                                                            val?.value ||
                                                            'page',
                                                    })
                                                }
                                            />
                                            <Input
                                                inputState={'normal'}
                                                name="key"
                                                onChange={handleInputChange}
                                                value={values.key}
                                                label="Key"
                                                className="mb-3"
                                            />
                                        </>
                                    ) : undefined}
                                    {form === 'edit' ? (
                                        <>
                                            <label htmlFor="key">
                                                Variable
                                            </label>
                                            <Select
                                                className="select__container mb-3"
                                                classNamePrefix="select"
                                                isDisabled={false}
                                                isSearchable
                                                name="key"
                                                options={varOptions}
                                                onChange={v => {
                                                    const [scope, ...key] =
                                                        v?.label?.split('.') ||
                                                        [];
                                                    setValues({
                                                        scope,
                                                        key: key.join('.'),
                                                        value: v?.value || '',
                                                    });
                                                }}
                                            />
                                        </>
                                    ) : undefined}
                                    <Input
                                        inputState={'normal'}
                                        name="value"
                                        onChange={handleInputChange}
                                        value={values.value}
                                        label="Valor"
                                        type="text"
                                        className="mb-5"
                                    />
                                    <Button
                                        className="w-100"
                                        text={`${
                                            form === 'add' ? 'Crear' : 'Editar'
                                        } variable`}
                                        disabled={
                                            !values.value ||
                                            values.value === 'false'
                                        }
                                        type="submit"
                                    />
                                </form>
                            </Col>
                        )}
                    </Row>
                </Container>
            ) : undefined}
        </main>
    );
}

export default ConfigForm;
